<script>
    import Card, {Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons} from '@smui/card';
    import {articlesStore} from '../store/articles-store';

    export let articleId;

    $: articlesStore.setSelectedArticle(articleId);
</script>

<div class="card article-rendered">
    <Card>
        <div class="card-header">
            <h2 class="mdc-typography--headline6">{$articlesStore.selectedArticle?.title}</h2>
            <h3 class="mdc-typography--subtitle2"
                style="margin: 0; color: #888;">{$articlesStore.selectedArticle?.releaseDate}</h3>
        </div>
        <Content class="mdc-typography--body2">
            {@html $articlesStore.selectedArticle?.article}
        </Content>
    </Card>
</div>

<style type="text/scss">
  .card {
    margin: 1rem auto;
    max-width: 1024px;

    .card-header {
      padding: 1rem;
    }

    h2 {
      margin: 0;
    }
  }
</style>
