import './static/articles/svelte-first-impressions.md';
import es6SpreadOperatorInAction from './static/articles/es6-spread-operator-in-action.md';
import article1 from './static/articles/deploying-angular-to-cloud-foundry.md';
import article2 from './static/articles/angular-4-redux.md';
import article3 from './static/articles/making-a-node-executable-with-typescript.md';
import article4 from './static/articles/tdd-with-react-and-enzyme.md';
import article5 from './static/articles/redux-saga-typescript-to-es5-with-webpack.md';
import article6 from './static/articles/angular4-redux-forms.md';
import article7 from './static/articles/how-to-disable-angular2-animations-for-E2E-protractor-testing.md';
import article8 from './static/articles/redux-new-relic-for-tracking-all-of-the-things.md';
import article9 from './static/articles/webdriverio-and-react.md';
export const articles = [
    // {
    //     id: 'svelte-first-impressions',
    //     title: 'Svelte First Impressions',
    //     description: 'My first impressions of the svelte framework',
    //     releaseDate: 'Jan 24th 2021',
    //     article: svelteFirstImpressions,
    //     imgUrl: 'images/svelte-first-impressions/article-image.png'
    // },
    {
        id: 'es6-spread-operator-in-action',
        title: 'ES6 Spread Operator',
        description: 'ES6 Spread Operator in Action',
        releaseDate: 'Jan 24th 2019',
        article: es6SpreadOperatorInAction,
        imgUrl: 'images/es6-spread-operator-in-action/article-image.png'
    },
    {
        id: 'deploying-angular-to-cloud-foundry',
        title: 'Deploying Angular to Cloud Foundry',
        description: 'Running an app on Cloud Foundry with angular-cli',
        releaseDate: '2017-12-17',
        article: article1,
        imgUrl: 'https://s3.eu-west-1.amazonaws.com/el-davo-blog/pcf.png'
    },
    {
        id: 'angular-4-redux',
        title: 'Angular4 Redux',
        description: 'How to add redux to angular 4',
        releaseDate: '2017-12-17',
        article: article2,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/ng2redux.png'
    },
    {
        id: 'making-a-node-executable-in-typescript',
        title: 'Making a node executable with Typescript',
        description: 'Shows how to make a node executable built with typescript',
        releaseDate: '2017-12-17',
        article: article3,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/typescript-npm.png'
    },
    {
        id: 'tdd-with-react-and-enzyme',
        title: 'TDD with React.js and Enzyme',
        description: 'Hands on testing of a react component with enzyme',
        releaseDate: '2017-12-17',
        article: article4,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/enzyme.png'
    },
    {
        id: 'redux-saga-typescript-to-es5-with-webpack',
        title: 'Redux-Saga typescript to es5 with Webpack',
        description: 'The trials of going to es5 with typescript and Redux-saga and webpack',
        releaseDate: '2017-12-17',
        article: article5,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/typescript-es5.png'
    },
    {
        id: 'angular4-redux-forms',
        title: 'Angular4 Redux Forms',
        description: 'How to integrate redux forms into your Angular4 application',
        releaseDate: '2017-12-17',
        article: article6,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/ng2react.png'
    },
    {
        id: 'how-to-disable-angular2-animations-for-E2E-protractor-testing',
        title: 'How to disable angular2 animations for E2E protractor testing',
        description: 'Disable animations when running protractor tests',
        releaseDate: '2017-12-17',
        article: article7,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/protractor.png'
    },
    {
        id: 'redux-new-relic-for-tracking-all-of-the-things',
        title: 'Redux New Relic for tracking all of the things!',
        description: 'How to track redux actions in new relic',
        releaseDate: '2017-12-17',
        article: article8,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/new-relic.png'
    },
    {
        id: 'webdriverio-and-react',
        title: 'Webdriverio and React',
        description: 'Demonstrates how to get webdriverio up and running with react webpack and travis with phantomjs',
        releaseDate: '2017-12-17',
        article: article9,
        imgUrl: 'https://s3-eu-west-1.amazonaws.com/el-davo-blog/webdriverio-logo.png'
    }
];
