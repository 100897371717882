<script>
    import List, {Item, Graphic, Text, PrimaryText, SecondaryText} from '@smui/list';
    import {link} from "svelte-routing";

    export let articles;
</script>

<List twoLine avatarList>
    {#each articles as article}
        <a href="/articles/{article.id}" use:link>
            <Item class="article">
                <Graphic class="avatar" style="background-image: url({article.imgUrl}); background-size: 40px 40px;"/>
                <Text>
                    <PrimaryText>{article.title}</PrimaryText>
                    <SecondaryText>{article.description}</SecondaryText>
                </Text>
            </Item>
        </a>
    {/each}
</List>

<style>
    a {
        text-decoration: none;
    }
</style>
