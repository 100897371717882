<script>
    import Card, {Content, Media, MediaContent, PrimaryAction} from '@smui/card';
    import List, {Item, Graphic, Text, PrimaryText, SecondaryText} from '@smui/list';
    import {articlesStore} from '../../store/articles-store';
    import {link} from "svelte-routing";
</script>

<Card>
    <Content class="mdc-typography--body2">
        <List twoLine avatarList>
            {#each $articlesStore.articles as article}
                <a href="/articles/{article.id}" use:link>
                    <Item>
                        <Graphic class="avatar"
                                 style="background-image: url({article.imgUrl}); background-size: 40px 40px;"/>
                        <Text>
                            <PrimaryText>{article.title}</PrimaryText>
                            <SecondaryText>{article.description}</SecondaryText>
                        </Text>
                    </Item>
                </a>
            {/each}
        </List>
    </Content>
</Card>
