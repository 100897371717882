import { writable } from 'svelte/store';
function createSideNavStore() {
    const state = { open: false };
    const { subscribe, set, update } = writable(state);
    return {
        subscribe,
        set,
        update,
        reset: () => update(() => state),
        toggleSideNav: () => update((state) => (Object.assign(Object.assign({}, state), { open: !state.open })))
    };
}
export const sideNavStore = createSideNavStore();
