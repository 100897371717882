import { writable } from 'svelte/store';
import { articles } from '../articles';
function createArticlesStore() {
    const state = { selectedArticle: null, articles };
    const { subscribe, set, update } = writable(state);
    return {
        subscribe,
        set,
        update,
        reset: () => update(() => state),
        setSelectedArticle: (articleId) => update((state) => (Object.assign(Object.assign({}, state), { selectedArticle: state.articles.find((article) => article.id === articleId) })))
    };
}
export const articlesStore = createArticlesStore();
