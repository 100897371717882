<script>
    import Drawer, {AppContent, Content, Header, Subtitle, Title, Scrim} from '@smui/drawer';
    import {articlesStore} from '../store/articles-store';
    import {sideNavStore} from '../store/side-nav-store';
    import ArticleList from './article-list/ArticleList.svelte';

    let drawer;
</script>

<Drawer variant="modal" bind:this={drawer} bind:open={$sideNavStore.open}>
    <Header>
        <Title>Articles</Title>
    </Header>
    <Content>
        <ArticleList articles={$articlesStore.articles}/>
    </Content>
</Drawer>
<Scrim />

<AppContent>
    <slot></slot>
</AppContent>
