<script lang="ts">
    import TopAppBar, {Row, Section, Title} from '@smui/top-app-bar';
    import IconButton from '@smui/icon-button';
    import {link} from "svelte-routing";
    import {sideNavStore} from '../store/side-nav-store';

</script>

<TopAppBar variant="fixed">
    <Row>
        <Section>
            <IconButton class="material-icons toggle-side-nav" on:click={sideNavStore.toggleSideNav}>menu
            </IconButton>
            <a href="/" use:link>
                <IconButton class="material-icons toggle-side-nav">home</IconButton>
            </a>
        </Section>
    </Row>
</TopAppBar>
